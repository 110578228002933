import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { COLORS } from "../utils/colors";
import GlobalStateContext from "../context/globalStateContext";
import UserContext from "../context/userContext";
import ScheduleAppointmentModal from "./ScheduleAppointmentModal";

const DoctorCard = ({ doctor }) => {
  const { user } = React.useContext(UserContext);
  const { globalState, setGlobalState } = React.useContext(GlobalStateContext);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const formatDistance = (distance) => {
    if (!distance) return "";
    return `${Math.round(distance)} miles away`;
  };

  return (
    <Box
      sx={{
        width: isMobile ? null : "40%",
        padding: 3,
        cursor: "default",
        margin: 2,
        borderRadius: 2,
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        border: `1px solid ${COLORS.nicePurple}`,
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="h6" color={COLORS.niceWhite}>
            {doctor.profession.includes("Coaching")
              ? doctor.name
              : `Dr. ${doctor.name}`}
          </Typography>
          {/* {!isMobile && (
            <Typography
              variant="subtitle1"
              color={COLORS.niceBlue}
              sx={{ opacity: 0.9, ml: 1 }}
            >
              {doctor.profession && doctor.profession}
            </Typography>
          )} */}
        </Box>
        <Typography variant="caption">
          {formatDistance(doctor.distance)}
        </Typography>
      </Box>
      {/* {isMobile && ( */}
      <Typography
        color={COLORS.niceBlue}
        sx={{ opacity: 0.9, paddingBottom: 0.5 }}
      >
        {doctor.profession && doctor.profession}
      </Typography>
      {/* )} */}
      {/* Remove the separate profession Typography that was here before */}
      <Box
        component="a"
        // href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        //   doctor.address
        // )}`}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          color: "inherit",
          textDecoration: "none",
          // "&:hover": { textDecoration: "underline" },
        }}
      >
        {doctor.address && (
          <Typography
            component="a"
            // href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
            //   doctor.address
            // )}`}
            target="_blank"
            rel="noopener noreferrer"
            color={COLORS.niceWhite}
            sx={{
              opacity: 0.8,
              marginTop: 0.5,
              lineHeight: 1.4,
              textDecoration: "none",
              // cursor: "pointer",
              // "&:hover": {
              //   opacity: 1,
              //   textDecoration: "underline",
              // },
            }}
          >
            {doctor.address
              .split(",")[0] // Take the first part which should always be the street
              .trim()
              .split(" ")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ")}
          </Typography>
        )}

        <Typography color={COLORS.niceWhite} sx={{ opacity: 0.8 }}>
          {doctor.city
            ?.split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
          ,{" "}
          {doctor.state
            ?.split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}
        </Typography>
      </Box>

      {doctor.phone && (
        <Typography
          onClick={() => {
            window.location.href = `tel:${doctor.phone}`;
          }}
          color={COLORS.niceWhite}
          sx={{
            opacity: 0.8,
            cursor: "pointer",
            "&:hover": {
              opacity: isMobile ? 1 : 0.8,
              textDecoration: "underline",
            },
          }}
        >
          {doctor.phone}
        </Typography>
      )}
      {doctor.email && (
        <Typography
          onClick={() => {
            window.location.href = `mailto:${doctor.email}`;
          }}
          color={COLORS.niceWhite}
          sx={{
            opacity: 0.8,
            cursor: "pointer",
            "&:hover": {
              opacity: isMobile ? 1 : 0.8,
              textDecoration: "underline",
            },
          }}
        >
          {doctor.email}
        </Typography>
      )}
      {/* SCHEDULE AN APPT  */}
      <Box sx={{ mt: 2 }}>
        {/* <Typography
          color={COLORS.niceWhite}
          sx={{
            opacity: 0.8,
            cursor: "pointer",
            textAlign: "center",
            padding: "8px 16px",
            border: `1px solid ${COLORS.nicePurple}`,
            borderRadius: 1,
            "&:hover": {
              opacity: 1,
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
          onClick={() => {
            if (!user) {
              setGlobalState({ showSignupModal: true });
            } else {
              // TODO: Add appointment scheduling logic here
              console.log("Schedule appointment with:", doctor.name);
            }
          }}
        >
          SCHEDULE AN APPOINTMENT
        </Typography> */}
        <Box sx={{ mt: 2 }}>
          <Typography
            color={COLORS.niceWhite}
            sx={{
              opacity: 1,
              color: "black",
              cursor: "pointer",
              textAlign: "center",
              padding: "8px 16px",
              background: COLORS.gradientPurplePink, // Changed this line
              border: `1px solid ${COLORS.niceBlack}`,
              borderRadius: 1,
              transition: "all 0.2s ease-in-out",
              fontWeight: 500,
              "&:hover": {
                opacity: 0.9,
                transform: "scale(1.02)",
                background: COLORS.gradientPurplePink, // Keep gradient on hover
              },
            }}
            onClick={() => {
              if (!user) {
                setGlobalState({ showSignupModal: true });
              } else {
                setGlobalState({
                  showScheduleModal: true,
                  activeDoctor: doctor,
                });
              }
            }}
          >
            Schedule an Appointment
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DoctorCard;
